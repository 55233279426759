import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import SpoilerIcon from "./spoiler-icon.svg";
export default class SpoilerPlugin extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('spoiler', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Spoiler',
                tooltip: true,
                icon: SpoilerIcon, // Assuming you have an icon definition for 'spoiler-plugin'
            });

            view.on('execute', () => {
                const spoilerLabel = prompt("Enter spoiler-plugin label:");

                if (spoilerLabel) {
                    const selection = editor.model.document.selection;
                    const selectedContent = editor.model.getSelectedContent(selection);

                    editor.model.change(writer => {
                        const spoilerBox = writer.createElement('spoilerBox');
                        const spoilerContent = writer.createElement('spoilerContent');

                        // Вставляем BBCode начало
                        writer.appendText(`[SPOILER="${spoilerLabel}"]`, spoilerContent);

                        // Вставляем содержимое спойлера с сохранением структуры и форматирования
                        for (const item of selectedContent.getChildren()) {
                            writer.append(item, spoilerContent);
                        }

                        // Вставляем BBCode конец
                        writer.appendText(`[/SPOILER]`, spoilerContent);

                        writer.append(spoilerContent, spoilerBox);
                        editor.model.insertContent(spoilerBox, selection);
                    });
                }
            });

            return view;
        });

    }
}