import React, {useState} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build/build/ckeditor"

const AuctionCkEditor = (props) => {

    const {handleMessageChange, isTabletOrMobile, showToolbar = false} = props

    const handleEditorChange = (event, editor) => {
        handleMessageChange(editor.getData())
    };

    const itemsArr = [
        'heading',
        '|',
        'bold',
        'italic',

        'bulletedList',
        'numberedList',
        'fontColor',
        'fontSize',
        '|',
        'outdent',
        'indent',
        '|',
        'link',
        'insertImageViaUrl',
        'blockQuote',
        'mediaEmbed',
        'undo',
        'redo',
        'spoiler'
    ];
    const items = (showToolbar === false) ? [] : (isTabletOrMobile) ? [] : itemsArr;

    const editorConfig = {
        toolbar: {
            items: items
        },
    };


    return (
        <CKEditor
            editor={Editor}
            config={editorConfig}
            data={props.message}
            onChange={handleEditorChange}
        />
    );
};

export default AuctionCkEditor;



